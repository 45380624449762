import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["loader"];

  startLoading(e) {
    this.loaderTarget.classList.remove("d-none");
    e.target.classList.add("disabled");
  }
}
