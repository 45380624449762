import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static values = { id: String, modalIdToOpen: String, opened: Boolean };

  connect() {
    if (this.openedValue) {
      this.open();
    }

    useClickOutside(this);
  }

  clickOutside(event) {
    const modalIdToHide = event.target.dataset.modalIdValue;
    if (modalIdToHide) {
      event.preventDefault();
      this.hideModal(modalIdToHide);
    }
  }

  close() {
    this.hideModal(this.idValue);
  }

  open() {
    this.showModal(this.idValue);
  }

  triggerOpenModal() {
    this.showModal(this.modalIdToOpenValue);
  }

  showModal(modalId) {
    const modal = document.getElementById(modalId);
    const backdrop = document.querySelector(".modal-backdrop");
    backdrop.style.display = "block";
    backdrop.classList.add("show");
    document.querySelector("body").classList.add("modal-open");
    modal.style.display = "block";
    modal.classList.add("show");
  }

  hideModal(modalId) {
    const modal = document.getElementById(modalId);
    document.querySelectorAll(".modal-backdrop").forEach((el) => {
      el.style.display = "none";
      el.classList.remove("show");
    });
    document.querySelector("body").classList.remove("modal-open");
    modal.style.display = "none";
    modal.classList.remove("show");
  }
}
