import { post, patch } from "@rails/request.js";
import { Features } from "@azure/communication-calling";

export default class AcsVisioRecordingHelper {
  constructor(roomId, aiNotetakerConfig, aiNotetakerBadgeStateTarget) {
    this.roomId = roomId
    this.startRecordingPath = `/rooms/${roomId}/recordings/start`
    this.pauseRecordingPath = `/rooms/${roomId}/recordings/pause`
    this.resumeRecordingPath = `/rooms/${roomId}/recordings/resume`
    this.acceptAiNotetakerConsentPath = `/rooms/${roomId}/recordings/accept_ai_notetaker_consent`
    this.declineAiNotetakerConsentPath = `/rooms/${roomId}/recordings/decline_ai_notetaker_consent`
    this.askRemoteParticipantForAiNotetakerConsentPath = `/rooms/${roomId}/recordings/ask_user_for_ai_notetaker_consent`
    this.aiNotetakerConfig = aiNotetakerConfig
    this.aiNotetakerBadgeStateTarget = aiNotetakerBadgeStateTarget
    this.recordingId = null
  }

  async subscribeToCall(call) {
    this.call = call
    call.on('remoteParticipantsUpdated', async e => {
      for (const remoteParticipant of e.added) {
        if (this.aiNotetakerConfig.enabled) {
          // both consents are given
          if (this.recordings.length === 0) {
            // start recording unless there is already one (whether in progress or paused)
            await this.startRecording(call)
          }
          await this.refreshRecordingBadgeState()
        } else if (this.aiNotetakerConfig.consent_given && this.aiNotetakerBadgeStateTarget[state].classList.contains('d-none')) {
          // only one user consent (the current user's) and badge isn't shown yet: give them the ability to trigger other user's consent modal
          await this.showAiNotetakerBadgeState('notStarted')
        }
      }

      for (const remoteParticipant of e.removed) {
        if (this.recordings.length === 0) {
          // hideAiNotetakerBadgeStates unless there is a recording (whether in progress or paused)
          await this.hideAiNotetakerBadgeStates()
        }
      }
    });


    call.feature(Features.Recording).on('isRecordingActiveChanged', async e => {
      // when recording active state was changed (perform start/pause/resume recording) for the current user
      const isRecordingActive = this.call.feature(Features.Recording).isRecordingActive

      if (isRecordingActive && (this.recordingId == null) ) {
        // recall startRecording to fetch the recording id if is not yet filled
        await this.startRecording(call)
      }

      await this.refreshRecordingBadgeState()
    })
  }
  async refreshRecordingBadgeState() {
    if (this.call.feature(Features.Recording).isRecordingActive) {
      await this.showAiNotetakerBadgeState('inProgress')
    } else {
      await this.showAiNotetakerBadgeState('paused')
    }
  }

  get recordings() {
   // get the recording for the current participant
   return this.call.feature(Features.Recording).recordings
  }

  async updateAiNotetakerBadgeStateTarget(aiNotetakerBadgeStateTarget) {
    this.aiNotetakerBadgeStateTarget = aiNotetakerBadgeStateTarget
  }

  async acceptAiNotetakerConsent() {
    this.aiNotetakerConfig.consent_given = true
    return await patch(this.acceptAiNotetakerConsentPath)
  }

  async declineAiNotetakerConsent(notifyOtherUser) {
    return await patch(this.declineAiNotetakerConsentPath, { body: { notify_other_user: notifyOtherUser } })
  }

  async showAiNotetakerBadgeState(state) {
    await this.hideAiNotetakerBadgeStates()
    const isRecordingState = state === 'paused' || state === 'inProgress'

    if (isRecordingState || (this.call.remoteParticipants.length === 1)) {
      // show badge when there recording or the other participant have also already joined the call
      this.aiNotetakerBadgeStateTarget[state].classList.remove('d-none');
    }
  }

  async hideAiNotetakerBadgeStates() {
    Object.values(this.aiNotetakerBadgeStateTarget).forEach(target => {
      target.classList.add('d-none')
    });
  }

  async askRemoteParticipantForAiNotetakerConsent() {
    await post(this.askRemoteParticipantForAiNotetakerConsentPath)
    await this.showAiNotetakerBadgeState('waiting')
  }

  async startRecording(call) {
    // getting the ACS technical serverCallId that the backend needs to start the recording
    const serverCallId = await call.info.getServerCallId()
    const response = await post(this.startRecordingPath, {
      body: {
        server_call_id: serverCallId
      }
    })
    if (response.ok) {
      const body = await response.json
      this.recordingId = body.recording_id
    }
  };

  async pauseRecording() {
    await post(this.pauseRecordingPath, {
      body: {
        recording_id: this.recordingId
      }
    })
  };

  async resumeRecording() {
    await post(this.resumeRecordingPath, {
      body: {
        recording_id: this.recordingId
      }
    })
  };
}
