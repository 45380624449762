import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    this.submitButtonTarget.disabled = true;

    this.element.addEventListener(
      "ui--autocomplete-custom:itemCountChanged",
      this.handleItemCountChange.bind(this),
    );
  }

  handleItemCountChange(event) {
    const itemCount = event.detail.count;
    this.submitButtonTarget.disabled = itemCount === 0;
  }
}
